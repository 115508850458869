//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StepLayout from './step-layout.vue';
import Button from '../components/button.vue';
import UiSwitch from '../components/switch.vue';
import emojiShrugging from '$resources/images/emoji-shrugging.png';
import IconUpload from '../components/icon-upload.vue';
import stepMixin from '../lib/step-mixin.js';

export default {
  components: {
    StepLayout,
    Button,
    UiSwitch,
    IconUpload
  },
  mixins: [stepMixin],
  props: {
    cups: {
      type: Object
    },
    channel: {
      type: String
    }
  },
  data: () => ({
    emojiShrugging,
    addBill: false,
    cupsId: '',
    loading: false,
    inputChanged: false,
    addFile: false,
    file: null
  }),
  watch: {
    // Eliminar espacios al escribir o pegar el CUPS
    cupsId(value) {
      const haveSpaces = value.indexOf(' ') !== -1 || value.match(/\s/g) !== null;

      if (haveSpaces) {
        this.cupsId = value.replace(/\s/g, '');
      }
    }
  },
  async created() {
    if (this.cups.type === 'cups') this.cupsId = this.cups.data;
    if (this.cups.type === 'pdf') this.addFile = true;
  },
  methods: {
    onFileDrop(event) {
      event.preventDefault();
      const [file] = event.dataTransfer.files;
      this.file = file;
    },
    sendGeneric() {
      this.loading = true;
      this.$emit('continue', {
        type: 'generic',
        data: ''
      });
    },
    handleInputChange() {
      const file = this.$refs.inputFile.files[0];

      this.file = file;
    },
    validadorCUPS() {
      const CUPS = this.cupsId;
      const RegExPattern = /^ES[0-9]{16}[a-zA-Z]{2}[0-9]{0,1}[a-zA-Z]{0,1}$/;

      if (CUPS.match(RegExPattern) && CUPS !== '') {
        const CUPS_16 = CUPS.substr(2, 16);
        const control = CUPS.substr(18, 2);
        const letters = [
          'T',
          'R',
          'W',
          'A',
          'G',
          'M',
          'Y',
          'F',
          'P',
          'D',
          'X',
          'B',
          'N',
          'J',
          'Z',
          'S',
          'Q',
          'V',
          'H',
          'L',
          'C',
          'K',
          'E'
        ];

        const fmodv = CUPS_16 % 529;
        const imod = parseInt(fmodv, 10);
        const quotient = Math.floor(imod / 23);
        const remainder = imod % 23;

        const dc1 = letters[quotient];
        const dc2 = letters[remainder];
        return control === dc1 + dc2;
      }

      return false;
    },
    onContinue() {
      this.loading = true;

      if (this.addFile) {
        this.$emit('continue', {
          type: 'invoice-upload',
          action: 'hiring',
          data: this.file
        });
        return;
      }

      if (this.validadorCUPS()) {
        this.$emit('continue', {
          type: 'cups',
          action: 'hiring',
          data: this.cupsId
        });
      } else {
        this.loading = false;
        this.$notify.error('El CUPS introducido no es válido');
      }
    }
  }
};
